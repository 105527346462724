<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Partner Promotions List
            </div>
            <div class="subtitle-1 font-weight-light">
              A list of Partner Promotion
            </div>
          </template>
          <v-card-text>
            <v-row
              class="mx-0"
              align="center"
              justify="center"
              justify-md="start"
            >
              <v-col cols="12" md="8" lg="5" class="px-0">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="8"
                lg="7"
                class="px-0"
                id="email-invite-button-container"
              >
                <div style="text-align: right">
                  <v-btn
                    type="submit"
                    class="px-12 mb-3 mx-auto"
                    color="primary"
                    text-align=" right"
                    tile
                    depressed
                    bottom
                    @click="openDialog = true"
                  >Create Promotion
                  </v-btn
                  >
                </div>
              </v-col>

              <v-dialog v-model="openDialog" max-width="700px">
                <v-card
                  class="mx-auto"
                  max-width="700px"
                  outlined
                  style="height: 550px; overflow-y: auto"
                >
                  <v-row
                    class="mx-0"
                    align="center"
                    justify="center"
                    justify-md="start"
                    justify-end
                  >
                    <v-col cols="10" md="10" lg="10" class="px-0">
                      <div
                        class="venue-invite-popup-header"
                        style="
                          padding-left: 30px;
                          padding-right: 30px;
                          margin-top: 25px;
                        "
                      >
                        Partner Promotion
                      </div>
                    </v-col>
                    <v-col
                      cols="2"
                      md="2"
                      lg="2"
                      class="px-0"
                      style="
                        text-align: right;
                        padding-top: 30px;
                      "
                    >
                      <v-btn
                        icon
                        color="grey lighten-1"
                        @click="openDialog = false"
                      >
                        <v-icon class="dialog-close-icon">mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-card-text style="max-height: 400px">
                    <div
                      style="
                        margin-left: 30px;
                        margin-right: 30px;
                        margin-top: 25px;
                      "
                    >
                      <v-row justify="space-between">
                        <v-col
                          cols="12"
                          md="6"
                          class="py-2"
                          style="height: 50px"
                        >
                          <v-text-field
                            v-model="partnerName"
                            label="Partner Name"
                            outlined
                            dense
                            :error-messages="errorPartnerName"
                            placeholder="Partner Name"
                            class="custom-input-styles"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="py-2">
                          <!-- this is the file input to upload the logo image -->
                          <!-- prepend-icon
                          append-icon="mdi-paperclip" -->
                          <v-file-input
                            v-model="image"
                            :error-messages="errorImage"
                            label="Image"
                            placeholder="Choose Image"
                            accept="image/*"
                            clear-icon="mdi-close"
                            truncate-length="14"
                            clearable
                            show-size
                            outlined
                            required
                            hide-details="auto"
                            prepend-icon=""
                            prepend-inner-icon="mdi-paperclip"
                            dense
                          >
                          </v-file-input>
                          <v-row class="mx-3 mt-2 mb-6">
                            <span class="d-block text-left caption platinum--text text--darken-3 w-100">
                              Accepted formats: JPG, PNG(Up to 5Mb)
                            </span>
                          </v-row>
                        </v-col>
                      </v-row>
                      <!-- <v-row>
          <v-col>
            <v-text-field label="Partner Name" outlined dense placeholder="Partner Name"></v-text-field>
          </v-col>
          <v-col>
            <div style="position: relative;">
              <v-file-input label="Choose Image" outlined dense></v-file-input>
              <p style="font-family: 'TT Hoves'; font-style: normal; font-weight: 400; font-size: 14px; color: #000000; opacity: 0.5; position: absolute; top: 35px; left: 40px; margin: 0; font-size: 12px;">Accepted formats: JPG, PNG</p>
            </div>
          </v-col>
        </v-row> -->

                      <v-text-field
                        v-model="description"
                        label="Description of Promotion (Max. 250 characters)"
                        :error-messages="errorDescription"
                        :counter="250"
                        outlined
                        dense
                        class="invite-popup-input"
                      ></v-text-field>
                      <br/>

                      <v-textarea
                        v-model="details"
                        :error-messages="errorDetails"
                        :counter="750"
                        label="Promotion Details (Max. 750 characters)"
                        outlined
                        dense
                        placeholder="Promotion Details (Max. 750 characters)"
                        style="width: 598px; height: 198px; border-radius: 0px"
                      ></v-textarea>

                      <v-row justify="space-between">
                        <v-col cols="12" md="6" class="py-2">
                          <!-- this is the file input to upload the logo image -->
                          <v-file-input
                            v-model="document"
                            :error-messages="errorDocument"
                            label="Document"
                            placeholder="Choose File"
                            accept="file/*"
                            clear-icon="mdi-close"
                            truncate-length="14"
                            clearable
                            show-size
                            outlined
                            required
                            hide-details="auto"
                            prepend-icon=""
                            prepend-inner-icon="mdi-paperclip"
                            dense
                          ></v-file-input>
                          <v-row class="mx-3 mt-2 mb-6">
                            <span
                              class="d-block text-left caption platinum--text text--darken-3 w-100"
                            >Accepted formats: PDF(Up to 10Mb)</span
                            >
                          </v-row>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="py-2"
                          style="height: 50px"
                        >
                          <v-text-field
                            v-model="website"
                            :error-messages="errorWebsite"
                            label="Partner Website URL"
                            outlined
                            dense
                            placeholder="Partner Website URL"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <!-- <v-row>
          <v-col cols="12" md="6">
            <div style="position: relative;">
              <v-file-input label="Choose File" outlined dense></v-file-input>
              <p style="font-family: 'TT Hoves'; font-style: normal; font-weight: 400; font-size: 14px; color: #000000; opacity: 0.5; position: absolute; top: 35px; left: 20px; margin: 0; font-size: 12px;">Accepted formats: PDF (Up to 10Mb)</p>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="Partner Website URL" outlined dense placeholder="Partner Website URL"></v-text-field>
          </v-col>
        </v-row> -->

                      <v-row justify="space-between">
                        <v-col cols="12" md="6" class="py-0">
                          <!-- this is the file input to upload the logo image -->
                          <v-text-field
                            v-model="email"
                            :error-messages="errorEmail"
                            label="Partner Email"
                            outlined
                            dense
                            placeholder="Partner Email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                          <v-text-field
                            v-model="phoneNo"
                            :error-messages="errorPhoneNo"
                            label="Phone No"
                            outlined
                            dense
                            placeholder="Phone No"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row>
          <v-col cols="12" md="6">
            <v-text-field label="Partner Email" outlined dense placeholder="Partner Email"></v-text-field>
          </v-col>
        </v-row> -->
                      <v-row justify="space-between">
                        <v-col cols="12" md="6">
                          <div class="date-picker">
                            <v-menu v-model="fromMenu" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  v-model="fromDate"
                                  outlined
                                  dense
                                  label="Valid From"
                                  :placeholder="'Valid From'"
                                  :min="getCurrentDate()"
                                  readonly
                                  @click="fromMenu = true"
                                >
                                  <template v-slot:append>
                                    <v-icon @click="fromMenu = true">mdi-calendar</v-icon>
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="fromDate" :min="getCurrentDate()"
                                             @input="fromMenu = false"></v-date-picker>
                            </v-menu>
                          </div>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div class="date-picker">
                            <v-menu v-model="toMenu" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  v-model="toDate"
                                  outlined
                                  dense
                                  label="Valid To"
                                  :placeholder="'Valid To'"
                                  :min="fromDate"
                                  :disabled="!fromDate"
                                  readonly
                                  @click="toMenu = true"
                                >
                                  <template v-slot:append>
                                    <v-icon :disabled="!fromDate" @click="fromDate && (toMenu = true)">mdi-calendar
                                    </v-icon>
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="toDate" :min="fromDate" @input="toMenu = false"></v-date-picker>
                            </v-menu>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6"></v-col>
                        <v-col cols="6" md="3" class="py-1">
                          <v-btn
                            type="submit"
                            class="mb-3 ml-16"
                            color="secondary"
                            tile
                            depressed
                            bottom
                            @click="submitFn"
                            style="width: 150px; height: 36px; align: right"
                            :loading="isLoading"
                          >Submit
                          </v-btn
                          >
                        </v-col>
                      </v-row>
                      <br/>
                    </div>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="notification"
                persistent
                max-width="450"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Notify Organizers
                  </v-card-title>
                  <v-card-text>
                    <span>Promotion Name</span>
                    <v-text-field v-model="lastUp" style="width: 100%;height: 20px"></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="notification = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="sendNotification"
                      :loading="isLoading"
                    >
                      Send Notification
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="items"
                  :loading="isLoading"
                >
                  <template v-slot:item.name="{ item }">
                    <td>
                      <tr>
                        {{
                          item.name
                        }}
                      </tr>
                      <tr>
                        <span style="color:grey;">{{
                            item.email
                          }}</span>
                      </tr>
                    </td>
                  </template>

                  <template v-slot:item.decription="{ item }">
                    <td>{{ item.decription }}</td>
                  </template>

                  <template v-slot:item.startDate="{ item }">
                    <td>{{ item.startDate.split("T")[0] }}</td>
                  </template>

                  <template v-slot:item.endDate="{ item }">
                    <td>{{ item.endDate.split("T")[0] }}</td>
                  </template>

                  <template v-slot:item.website="{ item }">
                    <td>{{ item.website }}</td>
                  </template>

                  <template v-slot:item.rate="{ item }">
                    <td style="color: #35a7ff;font-weight: bold">{{ item.count }}</td>
                  </template>

                  <template v-slot:item.status="{ item }">
                    <td>
                      <div>
                        <label class="switch">
                          <input
                            type="checkbox"
                            v-model="item.status === 'enabled'"
                            @change="toggleSwitch(item.id, item.status)"
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    </td>
                  </template>
                  <!-- template for status (going to event rfp page) -->

                  <!-- <template v-slot:item.cityCount="{ item }">
                    <td :style="{ color: '#35A7FF' }">{{ item.cityCount }}</td>
                  </template> -->

                  <template v-slot:item.delete="{ item }">
                    <v-dialog
                      v-model="item.isDeleteModal"
                      :key="item._id"
                      persistent
                      max-width="400"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon color="red">
                          <font-awesome-icon :icon="['fas', 'trash-alt']" color="red"
                          />
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <h3 class="title text-start">
                            Are you sure you want to delete '{{ item.name }}'?
                          </h3>
                        </v-card-title>
                        <v-card-text>
                          <p class="subtitle-2 text-left">
                            You will be deleting all the data.
                          </p>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="item.isDeleteModal = false"
                          >No, Cancel
                          </v-btn
                          >
                          <v-btn color="error" text @click="deleteFn(item)"
                          >Yes, Delete
                          </v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>

                  <template v-slot:item.more="{ item }">
                    <v-btn
                      icon
                      color="grey lighten-1"
                      @click="RouteFn(item.id)"
                    >
                      <font-awesome-icon :icon="['fas', 'chevron-right']"/>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import RFPApicalls from "../../../services/rfp.service";
import PartnerPromotionsApicalls from "../../../services/partnerPromotions.service";
import Upload from "../../../services/imageUpload.service";
import hybridAPIs from "../../../services/hybrid.service";

export default {
  name: "Partner Promotions",
  data() {
    return {
      notification: false,
      lastUp: null,
      partnerName: null,
      image: null,
      description: null,
      details: null,
      document: null,
      website: null,
      email: null,
      phoneNo: null,
      fromDate: null,
      toDate: null,
      fromMenu: false,
      toMenu: false,
      openDialog: false,
      closeDialog: true,
      search: "",
      isLoading: false,
      items: [],
      errorPartnerName: null,
      errorImage: null,
      errorDescription: null,
      errorDetails: null,
      errorDocument: null,
      errorWebsite: null,
      errorEmail: null,
      errorPhoneNo: null,
      errorStartDate: null,
      errorEndDate: null,
      staticErrorMessages: {
        partnerName: {
          isEmpty: "Partner Name should not be empty",
        },
        image: {
          isEmpty: "Image is required",
        },
        description: {
          isEmpty: "Description should not be empty",
          isExceed: "Exceeded maximum character limit (250)",
        },
        details: {
          isEmpty: "Promotion Details should not be empty",
          isExceed: "Exceeded maximum character limit (750)",
        },
        document: {
          isEmpty: "Document Field should not be empty",
        },
        website: {
          isEmpty: "Website Link is required",
        },
        email: {
          isEmpty: "Email should not be empty",
        },
        phoneNo: {
          isEmpty: "Phone Number should not be empty",
        },
        fromDate: {
          isEmpty: "Start date should not be empty",
        },
        toDate: {
          isEmpty: "End date should not be empty",
        },
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          sortable: true,
          text: "Partner",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Description ",
          value: "description",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "From",
          value: "startDate",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "To",
          value: "endDate",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Part.Web Url",
          value: "website",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "CT Rate",
          value: "rate",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Status",
          value: "status",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Delete",
          value: "delete",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Action",
          value: "more",
          class: "primary--text title",
        },
      ];
    },
  },
  mounted() {
    this.initFn();
  },
  watch: {
    partnerName() {
      this.errorPartnerName = null;
    },
    image(value) {
      if (value && !this.isImageValid(value)) {
        this.errorImage =
          "Invalid image format. Only PNG and JPEG images are allowed.";
      } else if (value) {
        let size = value ? value.size / 1024 / 1024 : 0;
        if (size > 5) {
          this.errorImage = "Please select an image under 5MB";
        }
      } else {
        this.errorImage = null;
      }
    },
    description(value) {
      if (value.length > 250) {
        this.errorDescription = this.staticErrorMessages.description.isExceed;
      } else {
        this.errorDescription = null;
      }
    },
    details(value) {
      if (value.length > 750) {
        this.errorDetails = this.staticErrorMessages.details.isExceed;
      } else {
        this.errorDetails = null;
      }
      // this.errorDetails = null;
    },
    document(value) {
      if (value && !this.isPDF(value)) {
        this.errorDocument = "Invalid file format. Only PDF files are allowed.";
      } else if (value) {
        let size = value ? value.size / 1024 / 1024 : 0;
        if (size > 10) {
          this.errorDocument = "Please select a document under 10MB";
        }
      } else {
        this.errorDocument = null;
      }
    },
    website() {
      this.errorWebsite = null;
    },
    email() {
      this.errorEmail = null;
    },
    phoneNo() {
      this.errorPhoneNo = null;
    },
    // fromDate() {
    //   this.errorStartDate = null;
    // },
    toDate() {
      this.errorEndDate = null;
    },
    fromDate(newDate) {
      if (newDate && this.toDate && this.toDate < newDate) {
        this.toDate = newDate;
      } else {
        this.errorStartDate = null;
      }
    },
  },
  methods: {
    isImageValid(file) {
      const regex = /\.(jpg|png)$/;
      return regex.test(file.name);
    },
    isPDF(file) {
      const regex = /\.(pdf)$/;
      return regex.test(file.name);
    },
    initFn() {
      this.items = [];

      PartnerPromotionsApicalls.getAllPartnerPromotions().then((res) => {
        this.items = res.data.content;
        console.log(this.items);
      });
    },
    async submitFn() {
      if (
        this.image &&
        // this.document &&
        this.partnerName &&
        this.email &&
        // this.phoneNo &&
        this.website &&
        this.description &&
        this.details &&
        this.fromDate &&
        this.toDate
      ) {
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        let payload = {
          Path: `${Number(new Date())}`,
          Base64: await toBase64(this.image),
        };

        this.isLoading = true;
        let payload1;
        await Upload.imageUpload(payload).then((res) => {
          payload1 = {
            img: res.data.content,
          };
        });

        var formData = new FormData();
        formData.append("file", this.document);
        let payload2;
        if(this.document) {
          await PartnerPromotionsApicalls.uploadFile(formData).then((res) => {
            payload2 = {
              file: res.data.details,
            };
          });
        }else{
          payload2 = null;
        }

        this.isLoading = false;
        if (this.description.length <= 250 && this.details.length <= 750) {
          let payload3 = {
            name: this.partnerName,
            email: this.email,
            phoneNo: this.phoneNo?this.phoneNo:'',
            description: this.description,
            details: this.details,
            website: this.website,
            image: payload1.img,
            documentUploadURL: payload2?payload2.file:'',
            startDate: this.fromDate,
            endDate: this.toDate,
          };
          PartnerPromotionsApicalls.createPartnerPromotions(payload3).then(
            (res) => {
              if (res.data.message == "success") {
                let payload = {
                  snackBarText: "Promotion has been added successfully",
                  snackBarColor: "green",
                };
                this.lastUp = this.description;
                this.notification = true;
                this.clearFn();
                this.openDialog = false;
                this.$store.dispatch("notify/setSnackbarMessage", payload);
              }
            }
          );
          this.initFn();
        }
      } else {
        if (!this.partnerName) {
          this.errorPartnerName = this.staticErrorMessages.partnerName.isEmpty;
        }
        if (!this.image) {
          this.errorImage = this.staticErrorMessages.image.isEmpty;
        }
        if (!this.description) {
          this.errorDescription = this.staticErrorMessages.description.isEmpty;
        }
        if (!this.details) {
          this.errorDetails = this.staticErrorMessages.details.isEmpty;
        }
        // if (!this.document) {
        //   this.errorDocument = this.staticErrorMessages.document.isEmpty;
        // }
        if (!this.website) {
          this.errorWebsite = this.staticErrorMessages.website.isEmpty;
        }
        if (!this.email) {
          this.errorEmail = this.staticErrorMessages.email.isEmpty;
        }
        // if (!this.phoneNo) {
        //   this.errorPhoneNo = this.staticErrorMessages.phoneNo.isEmpty;
        // }
        if (!this.fromDate) {
          this.errorStartDate = this.staticErrorMessages.fromDate.isEmpty;
        }
        if (!this.toDate) {
          this.errorEndDate = this.staticErrorMessages.toDate.isEmpty;
        }
      }
    },
    sendNotification() {
      if (!this.lastUp || this.lastUp.length < 1) {
        this.$store.dispatch("notify/setSnackbarMessage", {
          snackBarText: "Document name is not valid!",
          snackBarColor: "orange",
        });
        return
      }
      const data = {
        name: this.lastUp
      }
      hybridAPIs.triggerPromotionNotification(data);
      this.notification = false;
    },
    toggleSwitch(id, status) {
      if (status == "enabled") {
        let payload = {
          id: id,
          status: "disabled",
        };

        PartnerPromotionsApicalls.updateStatus(payload).then((res) => {
          if (res.data.code == 200) {
            let payload = {
              snackBarText: "Status has been updated successfully",
              snackBarColor: "secondary",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload);
            this.initFn();
          }
        });
      } else {
        let payload = {
          id: id,
          status: "enabled",
        };

        PartnerPromotionsApicalls.updateStatus(payload).then((res) => {
          if (res.data.code == 200) {
            let payload = {
              snackBarText: "Status has been updated successfully",
              snackBarColor: "secondary",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload);
            this.initFn();
          }
        });
      }
    },
    deleteFn(val) {
      this.isLoading = true;
      let payload = {
        id: val.id,
      };
      console.log(payload);
      PartnerPromotionsApicalls.deletePartnerPromotions(payload).then((res) => {
        if (res.data.code == 200) {
          let payload = {
            snackBarText: "Promotion has been deleted successfully",
            snackBarColor: "secondary",
          };
          this.deleteDialog = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload);
          this.initFn();
          this.isLoading = false;
        }
      });
    },
    clearFn() {
      (this.partnerName = null),
        (this.email = null),
        (this.phoneNo = null),
        (this.description = null),
        (this.details = null),
        (this.website = null),
        (this.image = null),
        (this.document = null),
        (this.fromDate = null),
        (this.toDate = null);
    },
    validateFromDate() {
      if (!this.fromDate) {
        this.$toast.error("Please enter a valid From date first.");
      }
    },
    getCurrentDate() {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    submitForm() {
      // Perform form submission logic here
      console.log('Form submitted!');
      console.log('From Date:', this.fromDate);
      console.log('To Date:', this.toDate);
    },
    RouteFn(id) {
      this.$router.push(`/Partner-Promotion-View/${id}`);
    },
    updateToDateMinDate() {
      this.toDate = null; // Reset the "To" field when the "From" field changes
    },

    dateformat(val) {
      var date = new Date(val);
      var dateformatted =
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      return dateformatted;
    },
    // type(val1, val2) {
    //   var typ = null;
    //   if (val2 == undefined) {
    //     typ = val1;
    //   } else typ = val1 + "-" + val2;

    //   return typ;
    // },

    // venueCountFn(val) {
    //   // console.log(val);
    //   let count = 0;
    //   for (let k = 0; k < val.length; k++) {
    //     const element = val[k];
    //     count = count + element.venue.length;
    //   }
    //   return count;
    // },
  },
};
</script>
<style>
/* date-picker */
.date-picker {
  width: 100%;
}

.v-menu__content .v-date-picker {
  max-width: none;
}

.v-menu__content {
  width: auto !important;
  height: 450px !important;
  margin-top: px !important;
  margin-bottom: 0 !important;
  overflow: visible !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);

}

.v-date-picker {
  max-width: none !important;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}

/* pop up */
.venue-invite-popup-header {
  font-weight: bold;
  font-size: 20px;
  padding-left: 30px;
  padding-top: 15px;
}

.invite-popup-input {
  margin-bottom: 10px;
}

#email-invite-button-container {
  text-align: right;
}

/* switch */
.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 16.67px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 8.335px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 8.335px;
  width: 8.335px;
  left: 4.165px;
  bottom: 4.165px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #35a7ff;
}

input:checked + .slider:before {
  transform: translateX(8.335px);
}

.custom-font-size {
  font-size: 16px;
}
</style>
