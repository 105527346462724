/* eslint-disable */
import instance from "@/services/instance.js";

const UploadFile = payload => {
  return instance.instanceFileUpload
    .post("/organiser/upload", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};
const DeleteManual = payload => {
  return instance.instanceToken
    .post("/organiser/delete_manual", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};
const UpdateManual = payload => {
  return instance.instanceToken
    .post("/organiser/update_manual_image", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};
const GetManualFile = payload => {
  return instance.instanceToken
    .get("/organiser/manualupload", payload )
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};
const TriggerNotification = payload => {
  return instance.instanceFileUpload
    .post("/organiser/article_notification", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const TriggerPromotionNotification = payload => {
  return instance.instanceFileUpload
    .post("/organiser/promotion_notification", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

export default {
  uploadFile: UploadFile,
  deleteManual: DeleteManual,
  updateManual: UpdateManual,
  getManualFile: GetManualFile,
  triggerNotification: TriggerNotification,
  triggerPromotionNotification: TriggerPromotionNotification,
};
